.contact-info {
  background-color: #174F90;
}

.col2 {
  display: grid;
  grid-template-columns: 70% 30%;
}

.icon {
  color: white;
  margin-right: 0.5rem;
}

.contact-info a {
  color: #ddd;
  font-size: 1.2vw;
}


.get-whatsapp-quotes a {
  color: white !important;
}

.get-whatsapp-quotes {
  padding: 0.5vw;
  background-color: #FF9900;
  color: white;
  font-weight: bold;
  text-align: center;
  transform: scale(1, 0.9);
}

.nav-bar {
  display: grid;
  grid-template-columns: 2% 15% 78%;
  justify-items: end;
  position: sticky;
  width: 100%;
  z-index: 20;
  box-shadow: 0px 1px 5px #7a6969;
  background: #fff;
  align-items: center;
  justify-content: space-between;
}

.nav-bar nav ul {
  list-style: none;
}

.nav-bar nav ul li {
  position: relative;
  float: left;
}

.nav-bar nav ul li a {
  font-size: 1vw;
  padding: 1vw;
  color: black;
  display: block;
  text-decoration: none;
  text-transform: uppercase;
}

.nav-bar nav ul li a:hover {
  text-decoration: underline;
  text-decoration-color: #FF9900;
  text-underline-offset: 8px;
}

.nav-bar nav ul li ul {
  position: absolute;
  left: 0;
  width: 300px;
  padding: 0;
  list-style: none;
  display: none;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  max-height: 50vh;
  overflow: scroll;
}

.nav-bar nav ul li ul li {
  background: white;
  width: 100%;
}

.nav-bar nav ul li:hover>ul {
  display: inline;
}

#menu-bar {
  display: none;
}

label {
  font-size: 7vw;
  cursor: pointer;
  display: none;
  margin-right: 2vw;
}

@media (min-width:1600px) {
  .nav-bar nav ul li ul {
    width: 600px;
  }

}

@media (max-width: 600px) {
  .header_icon {
    padding-left: 10vw;
    transform: scale(2.5);
    margin-top: -2vw;
  }

  .nav-bar nav ul li a {
    font-size: 4vw !important;
    padding: 2.5vw 0 !important;
  }

  label {
    display: initial;
  }

  .nav-bar nav {
    position: absolute;
    top: 10vw;
    left: 0;
    right: 0;
    background: white;
    border-top: 1px solid black;
    display: none;
  }

  .nav-bar nav ul li {
    width: 100%;
  }

  .nav-bar nav ul li ul {
    position: relative;
    width: 100%;
  }

  #menu-bar:checked~nav {
    display: initial;
    margin-top: 3vw;
    max-height: 50vh;
    overflow: scroll;
  }

  .nav-bar {
    position: fixed;
    height: 10vw;
    padding: 10px 0;
  }

  .contact-info {
    display: none;
  }
}