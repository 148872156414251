.contact-us-info {
    padding: 3vw 4vw;
}

.contact-us-info-title {
    color: #FF9900;
    font-weight: 500;
    letter-spacing: 0.50px;
    font-size: 1.7vw;
    line-height: 3vw;
}

.contactus {
    background: #f9f9f9;
    padding: 20px;
}

.contact-us-icon {
    padding-right: 10px;
    color: #FF9900;
}

.contact-us-info-description {
    color: #666;
    font-size: 1.3vw;
    letter-spacing: 0.50px;
    line-height: 2vw;
    padding-bottom: 10px;
}

.contact-us-info-description a {
    color: #666;
}

@media (min-width:1024px) {
    .contact-us-info {
        padding: 2vw 10vw;
    }
}

@media (max-width:567px) {
    .contact-us-info-description {
        font-size: 3vw !important;
        line-height: 5vw !important;
    }

    .contact-us-info-title {
        font-size: 4vw !important;
    }
}