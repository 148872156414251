.table-responsive {
    overflow-x: auto;
    min-height: 0.01%;
}

@media (max-width: 767px) {
    .table-responsive {
        width: 100%;
        margin-bottom: 15px;
        overflow-y: hidden;
    }
}

td {
    padding: 5px 8px;
    text-align: center;
}

table,
th,
td {
    border: 1px solid black;
    border-collapse: collapse;
}

table tbody tr th {
    background-color: #174F90;
    color: #f9f9f9;
    text-align: center
}