.about-page-testing-facilities-list {
    padding: 0 10vw;
    color: #777;
    background: #f9f9f9;
    margin-top: -8vw;
}

.about-page-testing-facilities-list-title {
    font-size: 1.5vw;
    color: #FF9900;
    font-weight: 500;
    padding-bottom: 10px;
}

.manufacturing-process-description {
    font-size: 1vw;
    color: #777;
    letter-spacing: 0.50px;
    line-height: 2vw;
}

.about-page-testing-facilities-list ul li {
    font-size: 1vw;
    letter-spacing: 0.50px;
    line-height: 2vw;
}

.about-page-testing-facilities-list u {
    margin: 10px;
}

@media (max-width:576px) {
    .about-page-testing-facilities-list-title {
        font-size: 5vw !important;
    }

    .about-page-testing-facilities-list ul li {
        font-size: 3vw !important;
        line-height: 6vw !important;
    }

    .about-page-testing-facilities-list {
        margin-top: 0;
    }

    .manufacturing-process-description {
        font-size: 3vw !important;
        line-height: 5vw !important;
    }
}