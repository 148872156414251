/* home page slider */
.home {
    font-size: 1vw;
}

.home_action {
    padding: 1vw 0 10vw 10vw;
}

.home_but {
    border: 1px solid #777;
    background: #FF9900;
    color: white;
    cursor: pointer;
    font-size: 1.2vw;
    font-weight: bold;
    padding: 1vw 1vw;
    text-align: center;
    text-transform: uppercase;
    max-width: 800px;
    margin-right: 1vw;
}

.left-right-icon {
    font-weight: bold;
    font-size: 2vw;
    color: black;
}

.home_but:hover {
    border: 1px solid #777;
    background: #FF9900;
    transform: scale(1.1);
}


.home_slider-title {
    font-size: 2.2vw;
    color: white;
    font-weight: 600;
    padding: 10vw 10vw 0 10vw;
    text-shadow: 3px 0px 7px rgba(81,67,21,0.8), -3px 0px 7px rgba(81,67,21,0.8), 0px 4px 7px rgba(81,67,21,0.8);
}

.home_slider-subtitle {
    font-size: 1.5vw;
    color: white;
    font-weight: 500;
    padding: 8px 0 15px;
    padding: 0 10vw;
    text-shadow: 3px 0px 7px rgba(81,67,21,0.8), -3px 0px 7px rgba(81,67,21,0.8), 0px 4px 7px rgba(81,67,21,0.8);
}

.wcolor {
    color: white;
}

@media (max-width: 576px) {
    .left-right-icon {
        font-size: 5vw !important;
    }

    .col1 {
        padding: 5vw 0 !important;
    }

    .home {
        padding-top: 10vw !important;
    }

    .home_slider-title {
        font-size: 3vw !important;
    }

    .home_slider-subtitle {
        font-size: 2.5vw !important;
    }

    .home_action {
        padding: 1vw 0 10vw 10vw;
    }

    .about_description,
    .product_description {
        font-size: 3vw !important;
        line-height: 6vw !important;
    }

    .about_title,
    .product_title,
    .brand_slider_title {
        font-size: 5vw !important;
        line-height: 10vw !important;
    }

    .about_read_more {
        font-size: 3vw !important;
    }

    .about_col2_title {
        font-size: 4vw !important;
    }

    .m3 {
        margin-bottom: 15vw !important;
    }
}

.about_title,
.product_title,
.brand_slider_title {
    font-size: 1.5vw;
    color: #FF9900;
    font-weight: 500;
    text-decoration: underline;
    text-underline-offset: 10px;
    padding-bottom: 5px;
}

.about_description {
    color: #777;
    font-size: 1vw;
    letter-spacing: 0.50px;
    line-height: 2vw;
}

.product_description {
    color: #777;
    font-size: 1.2vw;
}

.about_col2_icon {
    color: #FF9900;
    height: 2rem;
    padding-right: 10px;
}

.about_col2_title {
    color: #666;
    font-size: 1.3vw;
}

.info_about_company {
    padding: 2vw 10vw;
    background: #f9f9f9;
}

.about_read_more {
    font-size: 1vw;
    font-weight: 500;
}

.col3 {
    padding: 2vw 5vw 5vw;
    margin-top: 2vw;
}

.col1 {
    padding: 5vw 10vw 10vw;
    margin-top: 2vw;
}

.product {
    text-align: center;
}

.align_left {
    float: left;
}

.brand-details {
    padding: 3vw 10vw 1vw;
    background-color: #6c6d713b;
    text-align: center;
}

.product_underline,
.brand_slider_underline {
    width: 10vw;
}

.m3 {
    margin-bottom: 5vw;
}

.alloy-info-small {
    text-align: center;
    border: 1px solid;
    width: 70px;
    border: 3px solid #1848A0;
    padding: 3px;
    border-radius: 100%;
    height: 70px;
    font-size: 15px;
}

.alloy-info-big {
    text-align: center;
    border: 1px solid;
    width: 150px;
    border: 3px solid #1848A0;
    padding: 8px;
    border-radius: 100%;
    height: 150px;
    margin: 0 auto;
    font-size: 30px;
}

.alloy-title {
    font-size: 20px;
    text-align: center;
    padding-top: 1vw;
}

.alloy {
    padding: 2vw 10vw 5vw;
}