.all-products-card {
   padding: 0 7vw;
}

.product-info {
   padding: 3vw 5vw;
}

.product-img {
   border-radius: 10px;
   height: 40vw;
}

.product-info-title {
   font-size: 1.5vw;
   color: #FF9900;
   font-weight: 500;
   padding-bottom: 10px;
   letter-spacing: 0.50px;
   line-height: 2vw;
}

.product-info-description {
   color: #777;
   font-size: 1vw;
   letter-spacing: 0.50px;
   line-height: 2vw;
   padding-bottom: 10px;
}

.social-media-btn-on-product-info a {
   color: #777;
}

.social-media-btn-on-product-info {
   text-align: center;
   margin: 3px;
   background: #fff;
   padding: 1.2vw 0;
   box-shadow: 3px 4px 15px #00000026;
   border: 1px solid grey;
   border-bottom: 3px solid #FF9900;
   border-radius: 55px;
   width: 100%;
   font-size: 1.5vw;
}

.markup-text {
   padding: 1vw;
   color: #333;
   font-weight: 600;
   text-align: left;
   border-left: 1vw solid #333 !important;
   background-color: #FF9900;
   border-radius: 30px;
   font-size: 1.5vw;
}

.pipes-type-name {
   text-align: center;
}

@media (max-width:768px) {
   .social-media-btn-on-product-info {
      font-size: 2.5vw;
   }

   .markup-text {
      font-size: 2.1vw;
      padding: 2vw;
   }
}

@media (min-width:1024px) {
   .product-info {
      padding: 5vw 10vw;
   }
   .product-img {
      height: 15vw;
   }
}

@media (max-width:567px) {
   .social-media-btn-on-product-info {
      padding: 2.5vw 0;
      font-size: 4vw;
   }

   .product-info-title {
      font-size: 3.5vw !important;
      line-height: 6vw;
   }

   .product-info-description {
      font-size: 3vw !important;
      line-height: 5vw !important;
   }
}