.quality-policy-info {
    padding: 3vw 4vw;
    background: #f9f9f9;
}

.quality-policy-img {
    border-radius: 10px;
}

.quality-policy-info-title {
    color: #FF9900;
    font-weight: 500;
    padding-bottom: 10px;
    letter-spacing: 0.50px;
    font-size: 1.5vw;
    line-height: 4vw;
    text-decoration: underline;
    text-underline-offset: 8px;
}

.quality-policy-info-description {
    color: #777;
    font-size: 1vw;
    letter-spacing: 0.50px;
    line-height: 2vw;
    padding-bottom: 10px;
}

@media (min-width:1024px) {
    .quality-policy-info {
        padding: 2vw 10vw;
    }
}

@media (max-width:567px) {
    .quality-policy-info-title {
        font-size: 4vw !important;
        line-height: 6vw;
        text-decoration: underline;
        text-underline-offset: 5px;
    }

    .quality-policy-info-description {
        font-size: 3vw !important;
        line-height: 5vw !important;
    }
}