.enquiry {
    padding: 3vw 4vw;
}

@media (min-width:1024px) {
    .enquiry {
        padding: 2vw;
    }
}

.enquiry-title {
    color: #FF9900;
    font-weight: 500;
    letter-spacing: 0.50px;
    font-size: 1.7vw;
    line-height: 3vw;
    text-align: center;
    line-height: 5vw;
    text-decoration: underline;
    text-underline-offset: 5px;
}

.submit-btn {
    width: 100%;
    background-color: #FF9900;
    color: white;
    border-radius: 30px;
    font-size: 1.5vw;
    padding-bottom: 3vw;
}

.enquiry-page-title {
    font-size: 3vw;
    color: white;
    text-align: center;
    padding: 2vw 0;
    background-color: #FF9900;
}

.submit-btn:hover {
    background-color: #FF9900 !important;
    color: black;
}

.enquiry_description {
    text-align: center;
    color: #777;
    font-size: 1vw;
    letter-spacing: 0.50px;
    line-height: 2vw;
    padding-bottom: 10px;
}

.enquiry-form {
    background: #f0f0f0;
    padding: 20px;
}

.input-box {
    padding: 8px;
}

@media (max-width:567px) {
    .enquiry-page-title {
        font-size: 4vw !important;
        padding: 20vw 0 5vw !important;
    }

    .enquiry-title {
        font-size: 4vw !important;
        line-height: 9vw !important;
    }

    .enquiry_description {
        font-size: 3vw !important;
        line-height: 5vw !important;
    }

    .submit-btn {
        font-size: 4vw !important;
    }
}