.footer {
    padding: 2vw 10vw;
    background-color: #174F90;
    color: #ddd;
    font-size: medium;
    line-height: 1.7;
}

.social-media {
    border: 1px solid #ddd;
    border-radius: 50%;
    padding: 1vw 1vw;
    transform: scale(1.2);
    margin: 1vw;
}

.social_media_icon {
    color: #FF9900;
}

@media (min-width:1600px) {
    .footer a {
        color: blue !important;
        font-size: medium;
    }

    .footer_text,
    .footer a {
        font-size: 1vw;
    }

    .footer_social_media {
        transform: scale(2);
        padding-left: 10vw;
        padding-top: 1vw;
        padding-bottom: 1vw;
    }
}