.app {
    margin: 0;
    padding: 0;
    width: 100%;
    background: #f9f9f9;
}

.page-title-bg {
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.page-title {
    color: white;
    font-size: 3vw;
    text-align: center;
    padding: 6vw;
    font-weight: bold;
    text-shadow: 3px 0px 7px rgb(81 67 21 / 80%), -3px 0px 7px rgb(81 67 21 / 80%), 0px 4px 7px rgb(81 67 21 / 80%);
}

@media (max-width:576px) {
    .page-header {
        padding-top: 14vw !important;
    }

    .page-title {
        font-size: 5vw;
        padding: 10vw;
    }
}