.product-card-title {
    color: #FF9900;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1.1vw;
}

.product-card-description {
    color: #777;
    font-size: 1vw;
    padding: 0 2vw;
}

.product_card_read_more {
    font-size: 1vw;
    padding-top: 5px;
}

.product_card_read_more:hover {
    text-shadow: 2px 2px 5px #a59999;
    transform: scale(1.1);
}

@media (max-width: 576px) {
    .product-card-title {
        font-size: 4vw;
    }

    .product-card-description {
        font-size: 3vw;
    }

    .product_card_read_more {
        font-size: 3vw;
    }
}

:where(.css-dev-only-do-not-override-1i9hnpv).ant-card .ant-card-body {
    padding: 12px;
}