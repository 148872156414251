.privacy_policy {
    max-width: 80vw;
    margin: 0 auto;
    padding: 2vw 0;
}

.privacy_policy_title {
    font-size: 1.5vw;
    color: #FF9900;
    font-weight: 500;
    padding-bottom: 5px;
}

.privacy_policy_description {
    color: #777;
    font-size: 1vw;
    letter-spacing: 0.50px;
    line-height: 2vw;
}

@media (max-width:576px) {
    .privacy_policy_title {
        font-size: 5vw !important;
    }

    .privacy_policy_description {
        font-size: 4vw !important;
        line-height: 6vw !important;
    }
}